import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Home from "./Home";
import Menu from "./Menu";
import Contact from "./Contact";
import Footer from "./Footer";

function Landingpage () {
    const [message, setMessage] = useState('');

    useEffect(() => {
      axios.get('api/message')
        .then(response => {
          console.log(response.data);
          setMessage(response.data.message)
        })
        .catch(error => console.error('Error fetching message:', error));
    }, []);

    return (
        <div className="Red-Background">
          <p>Message from the server: {message}</p>
        <div className="App">
          <Home />
          <Menu />
          {/* <About /> */}
          {/* <Testimonial /> */}
          <Contact />
          <Footer />
        </div>  
        </div>
      );
};

export default Landingpage;