import "./App.css";
import React from 'react';
import axios from 'axios';
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landingpage from "./components/landingpage";
// axios.defaults.baseURL = 'http://localhost:6000';

function App() {

  return (
    <div className="Red-Background">
    <div className="App">
    <Router>
      <Routes>
        <Route path='/' element={<Landingpage/>} />
      </Routes>
    </Router>
    </div>  
    </div>
  );
}

export default App;
