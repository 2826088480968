import React,{useState} from "react"

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');

  const validateEmail = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      setEmailError('Email is required');
    } else if (!emailPattern.test(value)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    validateEmail(value);
  };

  const onchangeName = (event) => {
    setName(event.target.value);
  }
  const onchangeEmail = (event) => {
    setEmail(event.target.value);
  }
  const onchangeMessage = (event) => {
    setMessage(event.target.value);
  }

  const sendContactInfo = async (event) => {
    event.preventDefault();
    if(emailError) return
    console.log("Name", name);
    console.log("email", email);
    console.log("message", message);

    document.getElementById("message").value = "";
    document.getElementById("name").value = "";
    document.getElementById("email").value = "";

    const requestOptions = {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        // Request body data (if sending JSON data)
        name: name,
        email: email,
        message: message,
      }),
    }
    const res = await fetch("/api/contact", requestOptions);
    if (res.status !== 200) {
      console.error("Error while calling the API");
      console.error(res.status);
      return;
    }
  }


  return (
    <div id="contact" className="contact-page-wrapper">
        <h2 className="primary-heading"> Homemakers, do you wanna join us?</h2>
        <h1 className="primary-heading"> Fill the form below</h1>
        <div className="contact-form-container">
        <textarea id="message" className = "contact-form-textarea" placeholder="Enter your message here..." onChange={onchangeMessage}></textarea>
        </div>
        <div className="contact-form-container">
            <input id="name" type="text" placeholder="Your Name" onChange={onchangeName}/>
        </div>
        <div className="contact-form-container">
      <input
        id="email"
        type="email"
        placeholder="Your Email"
        value={email}
        onChange={handleChange}
      />
      {emailError && <div className="error-message">{emailError}</div>}
    </div>
        <button id="contact-submit-button" className="secondary-button" onClick={sendContactInfo}>Submit</button>
    </div>
  );
};

export default Contact;